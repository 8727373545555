<!-- <template>
  <v-row class="row-center">
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="red darken-2"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
    <v-col
      cols="10"
      offset="1"
      xl="4"
      offset-xl="4"
      md="6"
      offset-md="3"
      sm="8"
      offset-sm="2"
      class="login_content"
    >
      <div class="form">
        <div class="form-style">
          <img src="@/assets/logo1.png" alt="Logo" />
          <div class="bloc-form">
            <h1 class="form-title">Vous avez oublié votre mot de passe ?</h1>
            <p class="init-password">
              Veuillez entrer votre nouveau mot de passe
            </p>
            <forgetPassword
              @emailOK="sendEmail"
              :fields="fields"
              :btn="btn"
            ></forgetPassword>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>-->
<template>
  <v-container id="pageLogin" fluid>
    <v-row align="center">
      <!-- <v-row align="center" justify="center"> -->
      <v-col
        cols="10"
        offset="1"
        xl="4"
        offset-xl="4"
        md="6"
        offset-md="3"
        sm="8"
        offset-sm="2"
        class="login_content"
      >
        <v-row align="center">
          <v-col
            cols="6"
            xl="10"
            offset="3"
            lg="9"
            offset-lg="1"
            md="10"
            offset-md="1"
            sm="10"
            offset-sm="1"
            class="form-style"
          >
            <v-card class="login">
              <v-row
                class="form"
                align="center"
                align-content="center"
                justify="center"
              >
                <v-col cols="12">
                  <img src="@/assets/logo1.png" alt="Logo" />
                  <v-row class="bloc-form">
                    <v-col>
                      <h1 class="form-title mt-12 mb-3">
                        Vous avez oublié votre mot de passe ?
                      </h1>
                      <p class="init-password">
                        Veuillez entrer votre nouveau mot de passe
                      </p>
                      <v-col cols="8" offset="2">
                        <sharedForm
                          id="formShared"
                          :fields="fields"
                          :btn="btn"
                          @formOK="sendEmail"
                        ></sharedForm>
                        <v-col cols="8" offset="2">
                          <v-btn
                            plain
                            width="100%"
                            class="connect fullwidth"
                            :elevation="0"
                            @click="back"
                            >Retour
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="red darken-2"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
  </v-container>
</template>


<script>
import sharedForm from "@/components/shared/form";
import api from "@/api/users";
import axios from "axios";
export default {
  components: {
    sharedForm,
  },

  data() {
    return {
      fields: [
        {
          type: "email",
          label: "Votre email",
          value: this.$route.params.email,
          rules: "required|email",
          placeholder: "Votre email",
          name: "email",
          readonly: true,
        },
        {
          type: "password",
          label: "Votre mot de passe *",
          value: "",
          rules: "required|min:8",
          placeholder: "Votre mot de passe",
          name: "new_password",
          ref: "password",
        },
        {
          type: "password",
          label: "Confirmer votre mot de passe *",
          value: "",
          rules: "required|min:8",
          placeholder: "Votre mot de passe",
          name: "password_confirm",
          ref: "confirm",
        },
      ],
      btn: [
        {
          label: "Demander un nouveau mot de passe",
          method: "connect",
          type: "btn",
        },
      ],

      snackbar: false,
      timeout: 3000,
      errors: [],
    };
  },

  methods: {
    sendEmail: function () {
      this.errors = [];
      let data = {};
      this.fields.forEach((field) => {
        data[field.name] = field.value;
      });
      console.log(data, "data");
      if (data.new_password != data.password_confirm) {
        this.snackbar = false;
        this.errors.push(
          "Les champs mot de passe et confirmation mot de passe ne correspondent pas "
        );
        this.snackbar = true;
      } else {
        // tester les mots de passes

        let regexMaj = /[A-Z]+/g;

        let regexSpecial = /[^a-zA-Z0-9]+/g;

        if (
          regexSpecial.test(data.new_password) &&
          regexMaj.test(data.new_password)
        ) {
          data.id = this.$route.params.id;
          axios.defaults.headers.common = {
            Authorization: `Bearer ${this.$route.params.token}`,
          };
          api.reinitPassword(data).then(() => {
            this.$router.push({ name: "login" });
          })  .catch((error) => {
                    if (
                        401 === error.response.status ||
                        400 === error.response.status ||
                        500 === error.response.status
                    ) {
                        this.errors = []
                        this.snackbar = true                       
                        this.errors.push(error.response.data.message);
                    } else {
                        // console.log('error not defined', error)
                    }
                });
        } else {
          this.snackbar = false;
          this.errors.push(
            "Le mot de passe doit contenir au moins une majuscule et un caractère spécial"
          );
          this.snackbar = true;
        }

        // aaaaaaA%aaaaa*aaa
      }
      // api.reinitPassword(data).then(() => {
      //   this.$router.push({ name: "login" });
      // });
      // alert("Email envoyé à l'adresse : " + this.fields[0].value);
    },
    back() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.container#pageLogin {
  align-items: center;
  display: flex;
  height: 100vh;
}

.v-card.login {
  height: 78vh;
}

.form {
  height: 100%;
}

.form-title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: #93b121;
  text-transform: uppercase;
}
.init-password {
  font-size: 0.8rem;
  font-weight: bold;
}
@media screen and (max-width: 1500px) {
  img {
    width: 80%;
  }
}

@media screen and (max-width: 1400px) {
  /* .v-card {
    height: 70vh;
  } */
}

@media screen and (max-width: 1366px) {
  img {
    width: 40% !important;
  }
}
@media screen and (max-width: 1000px) {
  .v-card.login {
    height: 60vh;
  }
}
</style>